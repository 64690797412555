import { createContext } from 'react'
import Api, { IApi } from '@api'
import { action, makeAutoObservable } from 'mobx'

import { saveToStorage } from '@helpers'

import { AuthStore } from './auth.stores'
import { CurrentOrderStore } from './current-order.stores'
import { DialogStore } from './dialog.stores'
import { OrdersStore } from './orders.stores'
import { ProductsStore } from './products.stores'
import { ToasterStore } from './toaster.stores'
import { UserStore } from './user.stores'
import { UsersStore } from './users.stores'
import { WeeksStore } from './weeks.stores'
import { SubscriptionsStore } from './subscriptions.stores'

export class Store {
  toaster: ToasterStore
  user: UserStore
  users: UsersStore
  orders: OrdersStore
  dialog: DialogStore
  auth: AuthStore
  weeks: WeeksStore
  api: IApi
  products: ProductsStore
  currentOrder: CurrentOrderStore
  subscriptions: SubscriptionsStore

  constructor() {
    makeAutoObservable(this)
    this.api = Api()
    this.auth = new AuthStore(this)
    this.toaster = new ToasterStore(this)
    this.user = new UserStore(this)
    this.users = new UsersStore(this)
    this.orders = new OrdersStore(this)
    this.dialog = new DialogStore(this)
    this.weeks = new WeeksStore(this)
    this.products = new ProductsStore(this)
    this.currentOrder = new CurrentOrderStore(this)
    this.subscriptions = new SubscriptionsStore(this)
  }

  @action
  set<S extends keyof Store, K extends keyof Store[S], A extends Store[S][K]>(
    store: S,
    key: K,
    value: A,
    save?: boolean,
  ) {
    if (!key || !store) return
    ;(this as Store)[store][key] = value
    if (save) saveToStorage(key as string, value)
  }

  @action
  resetStores = () => {
    this.auth.reset()
    this.user.reset()
    this.users.reset()
    this.orders.reset()
    this.dialog.reset()
    this.weeks.reset()
    this.products.reset()
  }
}

export const StoreContext = createContext<Store>(new Store())
