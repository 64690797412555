import { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { AcAnimatedFade } from '@animations'
import { Box, Divider } from '@mui/material'
import { observer } from 'mobx-react-lite'

import {
  AcButton,
  AcColumn,
  AcFormInput,
  AcFormProvider,
  AcRow,
  AcSelect,
  AcSwitch,
  AcTypography,
} from '@components'
import { KEYS, LABELS, WEEKDAYS_KEYS, WEEKDAYS_LABELS } from '@constants'
import { useBreakpoints, useStore } from '@hooks'
import { getBasicSubscriptionLuncherCounts } from '@utilities/helpers/get-basic-subscription-luncher-counts.helpers'

const PREFERENCE_OPTIONS = [
  {
    id: 1,
    label: LABELS.MIX,
    value: KEYS.MIX,
  },
  {
    id: 2,
    label: LABELS.VEGETARIAN,
    value: KEYS.VEGETARIAN,
  },
  {
    id: 3,
    label: LABELS.VEGAN,
    value: KEYS.VEGAN,
  },
]

const FormInputs = observer(() => {
  const { products } = useStore()
  const { watch, resetField } = useFormContext()

  const days = Object.entries(WEEKDAYS_KEYS).map(([key, value]) => ({
    key,
    value,
  }))

  const daysActive = [
    watch('monday'),
    watch('tuesday'),
    watch('wednesday'),
    watch('thursday'),
    watch('friday'),
  ]

  useEffect(() => {
    daysActive.forEach((day, i) => {
      if (day) return
      resetField(`days[${Object.keys(WEEKDAYS_KEYS)[i].toLowerCase()}]`)
    })
  }, [daysActive])

  const renderSaladMenu = useMemo(() => {
    return (
      <AcRow
        paddingBlock={4}
        flex="0 0 100%"
        justifyContent="space-between"
        alignItems="flex-start">
        <Box>
          <AcTypography
            bold
            color="green.main"
            removePadding>
            Salade menu
          </AcTypography>
          <AcTypography
            color="green.main"
            removePadding>
            Uitleg salademenu
          </AcTypography>
        </Box>
        <AcFormInput
          register="salad_active"
          Component={<AcSwitch label={LABELS.SALAD_ACTIVE_ARIA_LABEL} />}
        />
      </AcRow>
    )
  }, [])

  const renderDays = useMemo(() => {
    return days.map((day, i) => (
      <AcColumn>
        {i !== 0 && (
          <Divider
            sx={({ palette }) => ({
              color: palette.grey['300'],
            })}
          />
        )}
        <AcRow
          justifyContent="space-between"
          alignItems="center">
          <AcTypography
            color="green.main"
            removePadding>
            {WEEKDAYS_LABELS[day.key as keyof typeof WEEKDAYS_LABELS]}
          </AcTypography>
          <AcFormInput
            register={day.value.toLowerCase()}
            Component={
              <AcSwitch
                label={WEEKDAYS_LABELS[day.key as keyof typeof WEEKDAYS_LABELS]}
              />
            }
          />
        </AcRow>
        {daysActive[i] && (
          <AcAnimatedFade>
            <AcFormInput
              register={`days[${day.key.toLowerCase()}][luncher_count]`}
              Component={
                <AcSelect
                  required
                  fullWidth
                  value=""
                  id={`luncher-preferences-${i}-count`}
                  label={LABELS.LUNCHER_COUNT}
                  options={getBasicSubscriptionLuncherCounts()}
                />
              }
            />
            <AcFormInput
              register={`days[${day.key.toLowerCase()}][preference]`}
              Component={
                <AcSelect
                  required
                  fullWidth
                  value=""
                  id={`luncher-preferences-${i}`}
                  label={LABELS.LUNCH_PREFERENCES}
                  options={PREFERENCE_OPTIONS}
                />
              }
            />
          </AcAnimatedFade>
        )}
      </AcColumn>
    ))
  }, [daysActive])

  return (
    <>
      <AcRow
        pt={4}
        pb={6}>
        {renderSaladMenu}
      </AcRow>
      <AcRow mb={3}>
        <AcTypography
          bold
          color="green.main"
          removePadding>
          {LABELS.LUNCHER_COUNTS_AND_PREFERENCES}
        </AcTypography>
      </AcRow>
      <AcColumn gap={4}>{renderDays}</AcColumn>
      <AcRow paddingBlock={6}>
        <AcButton
          fullWidth
          loading={products.productRatingLoading}
          type="submit">
          {LABELS.SUBMIT_REQUEST}
        </AcButton>
      </AcRow>
    </>
  )
})

export const AcBasicSubscriptionModificationForm = observer(() => {
  const { toaster, subscriptions, user, dialog } = useStore()

  const initial = {
    salad_active: user.current?.customer?.has_salad || false,
  }

  const onSubmit = async (data: any) => {
    if (!data.days) {
      toaster.error({
        content: LABELS.MINIMUM_ONE_DAY,
      })
      return
    }

    try {
      await subscriptions.submitBasicSubscriptionModificationRequest(data)
      toaster.success({
        title: LABELS.REQUEST_SUBMITTED,
        content: LABELS.REQUEST_LUNCH_PROFILE_EDIT_ARRIVED,
      })
      dialog.close()
    } catch (err: any) {
      toaster.error({
        content: err.message,
      })
    }
  }

  return (
    <AcFormProvider
      initial={initial}
      onSubmit={onSubmit}
      flexGrow={1}>
      <FormInputs />
    </AcFormProvider>
  )
})
