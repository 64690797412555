import React, { useEffect, useMemo, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { InputAdornment } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import { AcHelpertext, AcIconButton, AcRow, AcTag } from '@components'

export interface IAcSelectOption {
  id: number | string
  value: string | number
  label: string
}

export interface IAcSelectBase {
  id: string
  label: string
  fullWidth?: boolean
  minWidth?: number
  options: IAcSelectOption[]
  startIcon?: React.ReactNode
  disabled?: boolean
  helperText?: string
  error?: string
  renderClearInput?: boolean
  required?: boolean
  onClearInput?: () => void
}

export interface IAcSelectSingle extends IAcSelectBase {
  allowMultiple?: false
  value: string | number
  onChange?: (value: IAcSelectSingle['value']) => void
}

export interface IAcSelectMultiple extends IAcSelectBase {
  allowMultiple?: true
  value: string[] | number[]
  onChange?: (value: IAcSelectMultiple['value']) => void
}

export type IAcSelect = IAcSelectSingle | IAcSelectMultiple

export const AcSelect = ({
  id,
  label,
  value,
  fullWidth = true,
  minWidth = 100,
  options,
  onChange,
  startIcon,
  disabled,
  helperText,
  error,
  allowMultiple = false,
  renderClearInput,
  required = false,
}: IAcSelect) => {
  const [val, setVal] = useState<typeof value | undefined>(value)

  useEffect(() => {
    if (value !== val && onChange) onChange(val as string & string[])
  }, [val])

  const renderClearInputButton = useMemo(() => {
    if (!val || !options?.length || !renderClearInput) return null
    return (
      <InputAdornment
        sx={{ marginRight: 4 }}
        position="end">
        <AcIconButton
          onClick={() => setVal(undefined)}
          sx={{ opacity: 0.5 }}>
          <ClearIcon fontSize="small" />
        </AcIconButton>
      </InputAdornment>
    )
  }, [val, options.length])

  const renderStartIcon = useMemo(() => {
    if (!startIcon) return null
    return <InputAdornment position="start">{startIcon}</InputAdornment>
  }, [startIcon])

  const renderValue = (selected: IAcSelect['value']) => {
    return (
      <AcRow gap={1}>
        {typeof selected === 'string' || typeof selected === 'number'
          ? selected
          : selected.map(item => <AcTag label={item.toString()} />)}
      </AcRow>
    )
  }

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        labelId={label}
        required={required}
        id={id}
        value={val}
        label={label}
        onChange={e => setVal(e.target.value)}
        disabled={disabled}
        multiple={allowMultiple}
        IconComponent={ExpandMoreIcon}
        // renderValue={renderValue}
        endAdornment={renderClearInputButton}
        startAdornment={renderStartIcon}
        MenuProps={{
          sx: {
            '.MuiMenu-paper': {
              backgroundColor: 'white.main',
            },
          },
        }}
        sx={{
          minWidth: minWidth,
          outlineColor: error && 'error.main',
          outlineWidth: error && 2,
        }}>
        {options.map((option: IAcSelectOption) => (
          <MenuItem
            key={option.id}
            value={option.value}
            sx={theme => ({
              fontSize: theme.typography.fontSize,
              maxHeight: theme.spacing(4),
              padding: theme.spacing(2),
              display: 'flex',
              justifyContent: 'space-between',
            })}>
            {allowMultiple ? (
              <>
                <Checkbox
                  checked={
                    !!(
                      Array.isArray(val) && (val as any)?.includes(option.value)
                    )
                  }
                />
                <ListItemText primary={option.label} />
              </>
            ) : (
              option.label
            )}
          </MenuItem>
        ))}
      </Select>
      {helperText && <AcHelpertext text={helperText} />}
      {error && (
        <AcHelpertext
          text={error}
          isError
        />
      )}
    </FormControl>
  )
}
