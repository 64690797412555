export const KEYS = {
  HOME: 'home',
  MIX: 'MIX',
  VEGETARIAN: 'VEGETARIAN',
  VEGAN: 'VEGAN',
  STATUS_CONFIRMED: 'confirmed',
  STATUS_FULFILLED: 'fulfilled',
  STATUS_PENDING: 'pending',
  STATUS_CANCELLED: 'cancelled',
  LUNCHER_ROLE: 'luncher',
  LUNCHR_ROLE_ID: 3,
  OFFICE_MANAGER_ROLE: 'office-manager',
  OFFICE_MANAGER_ROLE_ID: 2,
  SUPER_USER_ROLE: 'super-user',
  SUPER_USER_ROLE_ID: 1,
} as const

export const STORE_KEYS = {
  TOASTER: 'toaster',
  DIALOG: 'dialog',
  USER: 'user',
  USERS: 'users',
  ONBOARDING: 'onboarding',
  ORDERS: 'orders',
  PRODUCTS: 'products',
  FORM: 'form',
  AUTH: 'auth',
  WEEKS: 'weeks',
  CURRENT_ORDER: 'currentOrder',
  SUBSCRIPTIONS: 'subscriptions',
} as const

export const PREFERENCE_KEYS = {
  MIX: 'MIX',
  VEGETARIAN: 'VEGETARIAN',
  VEGAN: 'VEGAN',
}
