import type { AxiosError } from 'axios'
import { action, makeAutoObservable } from 'mobx'

import { extractAPIFieldErrors } from '@helpers'
import { Store } from '@stores'
import { STORE_KEYS, WEEKDAYS_KEYS } from '@constants'

export class SubscriptionsStore {
  store: Store
  loading: boolean

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.loading = false
  }

  @action
  submitBasicSubscriptionModificationRequest = async (data: any) => {
    if (data.days) {
      data.days = Object.entries(data.days).map(([key, value]) => ({
        day: key,
        // @ts-ignore
        ...value,
      }))
    }
    Object.keys(WEEKDAYS_KEYS).forEach((key: string) => {
      delete data[key.toLowerCase()]
    })
    try {
      this.store.set(STORE_KEYS.SUBSCRIPTIONS, 'loading', true)
      await this.store.api.subscriptions.submit_update_request(data)
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.SUBSCRIPTIONS, 'loading', true)
    }
  }
}
