import { useEffect, useMemo, useState } from 'react'
import { Switch } from '@mui/material'
import { observer } from 'mobx-react-lite'

interface IAcSwitch {
  checked?: boolean
  onChange?: (value: boolean) => void
  label: string
}

export const AcSwitch = observer(
  ({ checked = false, onChange, label }: IAcSwitch) => {
    const [val, setVal] = useState<typeof checked | undefined>(checked)

    useEffect(() => {
      if (checked !== val && onChange) onChange(val as boolean)
    }, [val])

    const labelProps = useMemo(() => {
      return { inputProps: { 'aria-label': label } }
    }, [label])

    return (
      <Switch
        defaultChecked={checked}
        onChange={() => setVal(!val)}
        {...labelProps}
      />
    )
  },
)
