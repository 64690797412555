import { useEffect, useMemo, useRef, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { IconButton } from '@mui/material'

import { AcTextInput, IAcTextInput } from '@components'
import { useBreakpoints } from '@hooks'

import { AcStyledNumberInput } from './AcStyledNumberInput'

type Omitted = 'variant'

export interface IAcNumberInput extends Omit<IAcTextInput, Omitted> {
  variant?: 'styled' | 'default'
  fullWidth?: boolean
  min?: number
  max?: number
  step?: number
  value?: number
  disabled?: boolean
}

export const AcNumberInput = ({
  variant = 'default',
  min,
  max,
  step = 1,
  value = 0,
  fullWidth,
  onChange,
  disabled = false,
  ...props
}: IAcNumberInput) => {
  const { isMobile } = useBreakpoints()
  const wrapper = useRef<HTMLDivElement | null>(null)
  const [val, setVal] = useState(value)

  const handleChange = (e: any) => {
    const input = !e.target.value ? 0 : e.target.value
    setVal(parseInt(input))
  }

  const subtract = () => {
    const inputRef = wrapper.current?.querySelector('input')
    if (inputRef) {
      if (!min || (min && val > min)) {
        if (val <= 0 && min === 0) return
        const newVal = val - step
        setVal(newVal)
      }
    }
  }

  const add = () => {
    const inputRef = wrapper.current?.querySelector('input')
    if (inputRef) {
      if (!max || (max && val < max)) {
        if (min && val < min) {
          return
        }
        // @ts-ignore
        const newVal = parseInt(val) + step
        setVal(newVal)
      }
    }
  }

  const inputType = useMemo(() => {
    return isMobile ? 'tel' : 'number'
  }, [isMobile])

  useEffect(() => {
    if (val !== value) {
      if (typeof value === 'string') {
        setVal(parseInt(value))
      } else {
        setVal(value)
      }
    }
  }, [value])

  useEffect(() => {
    const inputRef = wrapper.current?.querySelector('input')
    if (inputRef) {
      inputRef.value = val.toString()
    }
    onChange && onChange(val as any)
  }, [val])

  if (variant === 'styled') {
    return (
      <AcStyledNumberInput
        ref={wrapper}
        type={inputType}
        onChange={handleChange}
        fullWidth={fullWidth}
        startAdornment={
          <IconButton
            onClick={!disabled ? subtract : undefined}
            disabled={disabled}>
            <RemoveIcon sx={{ color: disabled ? 'gray.main' : 'green.main' }} />
          </IconButton>
        }
        endAdornment={
          <IconButton
            onClick={!disabled ? add : undefined}
            disabled={disabled}>
            <AddIcon sx={{ color: disabled ? 'gray.main' : 'green.main' }} />
          </IconButton>
        }
        disabled={disabled}
        inputProps={{
          min,
          max,
          step,
          value,
          disabled,
        }}
      />
    )
  }
  return (
    <AcTextInput
      type={inputType}
      onChange={handleChange}
      InputProps={{
        inputProps: {
          min,
          max,
          step,
          value,
        },
      }}
      {...props}
    />
  )
}
