export const getBasicSubscriptionLuncherCounts = (
  start = 10,
  stop = 40,
  step = 5,
) => {
  const counts = []
  for (let i = start; i <= stop; i += step) {
    counts.push({
      id: i,
      label: i.toString(),
      value: i,
    })
  }

  return counts
}
