import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { AcAnimatedFade } from '@animations'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined'
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined'
import { NAVIGATE_TO } from '@routes'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'

import soupSaladBG from '@assets/visuals/soup_salad_card_bg.png'
import {
  AcButton,
  AcCard,
  AcCharts,
  AcColumn,
  AcExtrasSwiper,
  AcIconButton,
  AcImage,
  AcOrderStatusChip,
  AcRow,
  AcTag,
  AcTypography,
} from '@components'
import { DIALOGS, LABELS, THEME_COLORS } from '@constants'
import { AcFormatDate } from '@helpers'
import { useStore } from '@hooks'
import { useBreakpoints } from '@hooks'
import { ILegendItem } from '@typings'

interface IAcDashboardWeekTab {
  week: string
}

export const AcDashboardWeekTab = observer(({ week }: IAcDashboardWeekTab) => {
  const { dialog, weeks, products, user } = useStore()
  const navigate = useNavigate()

  const { isMobile } = useBreakpoints()

  const flexDirection = useMemo(() => {
    return isMobile ? 'column' : 'row'
  }, [isMobile])

  const alignItems = useMemo(() => {
    return isMobile ? 'flex-start' : 'center'
  }, [isMobile])

  const deadlinePassed = useMemo(() => {
    return dayjs(weeks.current?.deadline).isBefore(dayjs())
  }, [weeks.current?.deadline])

  const luncherDeadlinePassed = useMemo(() => {
    return dayjs(weeks.current?.luncher_deadline).isBefore(dayjs())
  }, [weeks.current?.deadline])

  const lunchPreferencesButton = useMemo(() => {
    if (!user.hasApp) return null
    if (isMobile) {
      return (
        <AcIconButton
          onClick={() => dialog.open(DIALOGS.LUNCH_PREFERENCES)}
          sx={{ padding: 0 }}
          color="green">
          <TroubleshootOutlinedIcon />
        </AcIconButton>
      )
    }

    return (
      <AcButton
        onClick={() => dialog.open(DIALOGS.LUNCH_PREFERENCES)}
        startIcon={<TroubleshootOutlinedIcon />}
        variant="text">
        {LABELS.LUNCH_PREFERENCES}
      </AcButton>
    )
  }, [isMobile])

  const renderWeekMenuCard = useMemo(() => {
    if (!user.hasSalad && !user.hasSoup) return null
    return (
      <AcCard
        removePadding
        sx={{
          flex: 1,
          border: 0,
          backgroundColor: THEME_COLORS.BRAND_PINK,
        }}
        cardColor="pink">
        <AcColumn
          gap={0}
          justifyContent="space-between"
          sx={{ height: '100%' }}>
          <AcColumn
            padding={5}
            gap={2}>
            <AcTypography
              removePadding
              component="h2">
              {LABELS.WEEK_MENU}
            </AcTypography>
            <AcRow gap={2}>
              {user.hasSalad && (
                <AcTag
                  large
                  color="white"
                  label="Salade"
                />
              )}
              {user.hasSoup && (
                <AcTag
                  large
                  color="white"
                  label="Soep"
                />
              )}
            </AcRow>
          </AcColumn>
          <AcImage
            sx={{ float: 'right', borderRadius: '8px', maxWidth: '100%' }}
            src={soupSaladBG}
          />
        </AcColumn>
      </AcCard>
    )
  }, [user.current])

  const reminderButton = useMemo(() => {
    if (weeks?.current?.status || luncherDeadlinePassed) return null
    if (!user.hasApp) return null
    if (isMobile) {
      return (
        <AcIconButton
          sx={{ padding: 0 }}
          onClick={() => dialog.open(DIALOGS.SEND_REMINDER)}
          color="green">
          <NotificationsActiveOutlinedIcon />
        </AcIconButton>
      )
    }

    return (
      <AcButton
        startIcon={<NotificationsActiveOutlinedIcon />}
        onClick={() => dialog.open(DIALOGS.SEND_REMINDER)}
        variant="text">
        {LABELS.SEND_REMINDER}
      </AcButton>
    )
  }, [
    isMobile,
    user.isBasicSubscription,
    deadlinePassed,
    weeks.current?.status,
  ])

  const renderLegendRow = useMemo(() => {
    if (!weeks.current?.chart?.legend) return null

    return (
      <AcRow gap={6}>
        {weeks.legend?.map((item: ILegendItem) => {
          return (
            <AcColumn gap={0}>
              <AcTypography
                removePadding
                color="green.main"
                component="h3">
                {item.value}
              </AcTypography>
              <AcTypography color="green.main">{item.label}</AcTypography>
            </AcColumn>
          )
        })}
      </AcRow>
    )
  }, [weeks.current?.chart?.legend])

  const renderOrderButton = useMemo(() => {
    if (deadlinePassed || weeks?.current?.status) return null

    return (
      <>
        <AcTypography removePadding>
          {LABELS.FINALIZE_BY}{' '}
          {AcFormatDate(weeks.current?.deadline, '', 'D MMMM H:mm')}
        </AcTypography>
        <AcButton onClick={() => navigate(NAVIGATE_TO.CREATE_ORDER(week))}>
          {user?.isBasicSubscription
            ? LABELS.CHOOSE_EXTRAS
            : LABELS.START_ORDERING}
        </AcButton>
      </>
    )
  }, [weeks.current, deadlinePassed])

  const renderViewOrderButton = useMemo(() => {
    if (!user || user?.isBasicSubscription) return null
    if (!weeks?.current?.status || weeks?.current?.status !== 'cancelled') {
      const orderSpec = weeks?.current?.specification_id
      if (!orderSpec) return null
      return (
        <>
          {deadlinePassed ? (
            <>
              <AcTypography removePadding>
                {LABELS.ORDER_CANNOT_EDIT}
              </AcTypography>
              <AcButton
                onClick={() =>
                  navigate(NAVIGATE_TO.VIEW_ORDER(orderSpec.toString()))
                }>
                {LABELS.VIEW_ORDER}
              </AcButton>
            </>
          ) : (
            <>
              <AcTypography removePadding>
                {LABELS.ORDER_CAN_EDIT_BEFORE}{' '}
                {AcFormatDate(weeks.current?.deadline, '', 'D MMMM H:mm')}
              </AcTypography>
              <AcButton
                onClick={() =>
                  navigate(NAVIGATE_TO.EDIT_ORDER(week, orderSpec.toString()))
                }>
                {LABELS.EDIT_ORDER}
              </AcButton>
            </>
          )}
        </>
      )
    } else {
      return null
    }
  }, [weeks.current, deadlinePassed, user.isBasicSubscription])

  const customOrderStatus = useMemo(() => {
    // As per client request, status should be pending if deadline has not passed.
    if (!deadlinePassed && weeks?.current?.status === 'confirmed')
      return 'pending'
    return weeks?.current?.status
  }, [weeks?.current?.status])

  useEffect(() => {
    weeks.resetWeek()
    weeks.getWeek(week)
    weeks.getWeekLunchers(week)
    products.getExtras(week)
  }, [])

  if (!weeks.week) {
    return null
  }

  return (
    <AcAnimatedFade
      enter={{ x: 0, y: 20 }}
      exit={{ x: 0, y: -20 }}>
      <AcColumn gap={6}>
        <AcColumn>
          <AcRow
            alignItems={alignItems}
            sx={{ flexDirection, justifyContent: 'space-between' }}>
            <AcRow>
              <AcTypography
                color="green.main"
                removePadding
                component="h2">
                {LABELS.ORDER_WEEK} {weeks.current?.week}
              </AcTypography>
              <AcOrderStatusChip status={customOrderStatus} />
            </AcRow>
            <AcRow
              gap={5}
              alignItems={alignItems}
              sx={{ flexDirection }}>
              {renderOrderButton}
              {renderViewOrderButton}
            </AcRow>
          </AcRow>

          <AcRow
            spacing="lg"
            sx={{ flexDirection }}
            justifyContent="space-between">
            <AcCard
              sx={{
                flex: 2,
              }}>
              <AcColumn
                gap={5}
                justifyContent="space-between"
                height="100%">
                <AcRow
                  justifyContent="space-between"
                  alignItems="flex-start">
                  <AcTypography
                    color="green.main"
                    component="h2">
                    {LABELS.AMOUNTS}
                  </AcTypography>
                  <AcRow gap={5}>
                    {lunchPreferencesButton}
                    {reminderButton}
                  </AcRow>
                </AcRow>
                {renderLegendRow}
                {weeks.current?.chart && (
                  <AcCharts chartData={weeks.current?.chart} />
                )}
              </AcColumn>
            </AcCard>
            {renderWeekMenuCard}
          </AcRow>
        </AcColumn>

        <AcColumn>
          <AcColumn gap={0}>
            <AcTypography
              removePadding
              color="green.main"
              component="h2">
              {LABELS.EXTRAS}
            </AcTypography>
            {!isMobile && (
              <AcTypography removePadding>{LABELS.EXTRAS_TEXT}</AcTypography>
            )}
          </AcColumn>
          <AcExtrasSwiper items={products.extras} />
        </AcColumn>
      </AcColumn>
    </AcAnimatedFade>
  )
})
