import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import {
  IBasicSubscriptionModificationPayload,
  ISinglePortalUser,
  ISinglePortalUserInvitation,
} from '@typings'

export class SubscriptionsApi extends AxiosBaseApi {
  submit_update_request = (payload: IBasicSubscriptionModificationPayload) =>
    this.api
      .post<{
        data: { data: ISinglePortalUser }
      }>(ENDPOINTS.SUBSCRIPTIONS.INDEX, payload)
      .then(response => response.data)
}
